import logo from './logo.svg';

import LandingPage from './LandingPage';

function App() {
  return (
    <div className=' bg-neutral-900'>
       <LandingPage/>
       test
    </div>
  
  );
}

export default App;
