import React from 'react' 
import './index.css'
import { Link } from 'react-router-dom'
import chatgpt from './ai_logos/chatgpt.png'
import claude from './ai_logos/claude.png'
import mistral from './ai_logos/mistral.png'
import dashboard from './dashboard.png'

const LandingPage = () => { 



    return(
<div className='select-none flex '>
<div
  className=""
  style={{
    padding: '0px'
  }}
>
  <div>
    <div className="undefined">
      
      <div className="dark:text-zinc-100 mx-auto w-7/8 ">
        <div className="w-7/8 mx-auto relative h-4/5 z-30 pt-6 inter  ">
          <div className=" text-center pt-8 md:pt-12 md:mt-12 mx-auto w-4/5 md:w-3/5">
            <h1
              className="text-[#030647]  font-semibold text-5xl md:text-7xl dark:text-gray-200
              Inter"
            >
              {' '}Evaluate Different LLMs For Your Specific Use Case{' '}
            </h1>
            <p className="text-lg text-[#515568] pt-2 mt-4 text-center w-4/5  mx-auto dark:text-gray-100/70 Inter">
              Analytics tool that benchmarks different LLMs for your use case to help you choose the best LLM to deploy
            </p>
            <div className="flex flex-row mx-auto justify-center pt-6 mt-4">
              {/* <Link
                className="text-white bg-pageOrange hover:drop-shadow-lg focus:ring-4 text-2xl focus:ring-blue-300 font-semibold rounded-2xl text-sm px-12 py-4 mr-2 mb-2 dark:bg-pageOrange  dark:hover:bg-orange-700 focus:outline-none dark:focus:ring-blue-800"
                to={"/login"}
              >Try for free</Link> */}
            </div>
          </div>
        </div>
        <div className="rounded-xl relative mt-4  p-8 xl:pr-36 xl:pl-36 lg:pr-14 lg:pl-14 ">
          <div className='p-1 bg-pageOrange rounded drop-shadow-lg'>
  
    
            <img src={dashboard}/>

          
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div>
    <section>
      <section className="w-4/5 mx-auto">
        <div className="container mx-auto flex px-5 py-10 md:py-24 md:flex-row flex-col items-center">
   =
          
        </div>
      </section>
      <section className="w-4/5 mx-auto md:mt-0">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:w-1/2 md:w-4/5 mb-8 md:mb-0 flex flex-col md:items-start md:text-left items-center text-center text-white">
            <h1
              className="md:w-5/6 sm:text-4xl text-4xl mb-6 font-bold text-white
 Inter"
            >
              Compare different models to make the best decision
            </h1>
            <p className="mb-6 leading-relaxed font-normal text-lg md:w-4/5 Inter">
              <div className=' flex flex-col'>

              
              <p>- Save money choosing the correct LLM </p>
              <p>- Save time endlessly testing different models</p>
              <p>- Have a objective benchmark to test by to use the right model for your use case</p>
              </div>
              
              
            </p>
          
          </div>
      
          <div className="h-full  lg:ml-12 w-full  md:w-1/2 w-5/6 mb-10 md:mb-0 featureGradient2 ">
            <div className='flex flex-row justify-between '>
                <img src={chatgpt} width={120}></img>
                <img src={mistral} width={120}></img>
                <img src={claude} width={120}></img>
            </div>
          
          </div>
        </div>
      </section>
    </section>
  </div>  
  
 
  <div>
    <div className="mt-24 pt-12 pb-12 mb-24 w-[95%] md:w-4/5 mx-auto">
      <div className="rounded-2xl pt-20 pb-24 flex justify-center align-center shadow-lg undefined bg-interface">
        <div className="text-center text-white w-[80%] md:w-[60%] md:w-3/5 m-auto ">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold Inter">
            Input your email below and join the waiting list!
          </h1>
         
          <div className="pt-6">
        
          
          </div>
        </div>
      </div>
    </div>
  </div>
  <div />
  <div />
  <div />
  <div />
  <div>
    <div className="mt-24 pt-12 pb-12 mb-24 w-[95%] md:w-4/5 mx-auto flex justify-center">
      
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSemjYkdZz3GEg_VAdWi-7X9A1omn3sod2wblozkHXAeTtKN7A/viewform?embedded=true" width="640" height="585" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    
    </div>
  </div>
  <div>
    <div className="">
     
      <div className=" bg-background">
        <div className="flex mx-auto container justify-center py-6 text-center  text-[#C8C8C8]">
          <span className="font-normal Inter">
            Coming soon...
          </span>
        </div>
      </div>
    </div>
  </div>
</div> 
        </div>
    )   
}

export default LandingPage

